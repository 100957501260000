import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import GradientContainer from '../GradientContainer';
import {trackScheduleACallClick, trackSignUpClick} from 'utils/analytics';
import TrustedBySection from '../TrustedBySection';
// eslint-disable-next-line @typescript-eslint/promise-function-async
const RiveAnimation = dynamic(() => import('./RiveAnimation'), {});

const HeroSection = () => {
  return (
    <GradientContainer className="align-center flex flex-col justify-start">
      <div className="relative mx-auto mb-8 mt-[125px] flex flex-col items-center px-4 text-white lg:container lg:mt-[140px] lg:mb-8 lg:flex-row lg:items-center lg:justify-between lg:py-5">
        <div className="flex w-full flex-col items-center justify-center gap-y-8 lg:max-w-[550px] lg:items-start lg:justify-start lg:gap-y-7">
          <Link
            href="/blog/posts/2025/04/01/introducing-continuous-gpu-profiling"
            className="flex w-fit gap-3 rounded bg-white px-3 pt-2 pb-[9.6px] text-center text-gray-900"
          >
            <p className="!m-0 text-xs lg:text-sm">Announcing our new GPU profiling feature! 🚀</p>
          </Link>
          <div className="text-center text-4xl font-semibold leading-tight lg:text-left lg:text-[56px]">
            Discover the Performance Engineer in you.
          </div>
          <div className="max-w-[422px] whitespace-pre-line text-center text-base leading-6 text-gray-200 lg:max-w-prose lg:text-left lg:text-xl lg:leading-7">
            Polar Signals Cloud is an always-on, zero-instrumentation continuous profiling for CPU,
            GPU, and Memory that helps improve performance, understand incidents, and lower
            infrastructure costs.
          </div>
          <div className="flex flex-col gap-3 lg:flex-row">
            <a
              href="https://cloud.polarsignals.com/signup"
              className="flex w-full items-center justify-center rounded bg-white px-10 py-3 text-center font-sans text-base font-medium text-gray-700 first-line:text-center hover:opacity-90 lg:w-fit lg:py-4 lg:text-lg"
              onClick={() => trackSignUpClick('hero-trial-cta')}
            >
              <span>Start free 14-day trial</span>
            </a>
            <Link
              href="/schedule-a-call"
              className="flex w-full items-center justify-center rounded border-2 border-white px-10 py-3 text-center font-sans text-base font-medium text-white first-line:text-center hover:bg-white hover:text-gray-700 hover:opacity-90 lg:w-fit lg:py-4 lg:text-lg"
              onClick={() => trackScheduleACallClick('hero-cta')}
            >
              <span>Book a demo</span>
            </Link>
          </div>
        </div>
        <div className="relative mt-8 flex h-[250px] w-full items-center justify-center md:h-[400px] lg:m-0 lg:h-[700px] lg:w-[800px]">
          <RiveAnimation />
        </div>
      </div>
      <TrustedBySection />
    </GradientContainer>
  );
};

export default HeroSection;
