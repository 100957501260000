import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
  paddingTop?: string;
}

const IntroducingGPUProfiling = ({paddingTop = ''}: Props) => {
  return (
    <section className="bg-gradient-to-tr from-[#4C68F699] to-[#4C68F6] text-white">
      <div className="w-full bg-gradient-to-b from-[#a7ead533] to-[#A7EAD500]">
        <div
          className={cx(
            'container mx-auto flex w-full flex-col items-center gap-12 px-4 py-12 lg:gap-24 lg:py-32',
            {[paddingTop]: !!paddingTop}
          )}
        >
          <div className="flex max-w-[790px] flex-col items-center gap-4">
            <div className="lg:text-4.5xl text-center tracking-normal leading-[3.5rem] text-3xl font-semibold">
              Introducing Continuous Profiling for GPUs
            </div>
            <div className="text-brand-gray px-1 text-center font-sans text-base md:px-16 lg:px-10 lg:text-xl">
              Maximize GPU Efficiency: Visualize, Analyze, and Optimize with Precision.
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center gap-4">
            <div className="flex w-full">
              <div className="relative w-full aspect-[4/3]">
                <Image
                  src="/gpu/cpu-strips-rounded.png"
                  alt="A screenshot of the GPU Profiling Dashboard with multiple metrics"
                  fill
                  className="object-contain mt-0 mb-0"
                />
              </div>
            </div>

            <div className="flex flex-row gap-8 items-center">
              <div className="flex flex-col items-center gap-2">
                <div className="relative w-[50px] h-[40px] lg:w-[100px] lg:h-[81px]">
                  <Image
                    src="/gpu/python-logo.svg"
                    alt="Python"
                    layout="fill"
                    objectFit="contain"
                    className="mt-0"
                  />
                </div>
                <div className="relative w-[75px] h-[75px] lg:w-[150px] lg:h-[150px]">
                  <Image
                    src="/gpu/pytorch.svg"
                    alt="PyTorch"
                    layout="fill"
                    objectFit="contain"
                    className="mt-0"
                  />
                </div>
                <div className="relative w-[100px] h-[50px] lg:w-[200px] lg:h-[91px]">
                  <Image
                    src="/gpu/nvidia-cuda.svg"
                    alt="NVIDIA CUDA"
                    layout="fill"
                    objectFit="contain"
                    className="mt-0"
                  />
                </div>
              </div>
              <div className="bg-white w-[1px] h-[268px]" />
              <div className="flex flex-col gap-2">
                <p>
                  GPUs are a significant investment, and maximizing their utilization is key to
                  cost-effective operations.
                </p>

                <Link
                  href="/use-cases/gpu-profiling"
                  className="flex w-fit items-center justify-center rounded bg-white px-10 py-3 text-center font-sans text-base font-medium text-gray-700 first-line:text-center hover:opacity-90 lg:w-fit lg:py-4 lg:text-lg"
                >
                  <span>Learn more</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroducingGPUProfiling;
